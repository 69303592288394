import React, { useEffect } from "react"
import Layout from "../../components/Layout"
import ExploreText from "../../components/ExploreText"
import ExploreBox from "../../components/ExploreBox"
import BookingOnline from "../../components/BookingOnline"
import Aos from "aos"
import BackToTop from "../../components/BackToTop"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
import HistoryBlock from "../../components/HistoryBock"

const Explore = ({ data }) => {
  useEffect(() => {
    Aos.init()
  }, [])
  const { title, _rawDescription, gallery, exploreBig } =
    data?.sanityPages?.exploresPage
    console.log('data===', gallery);
  return (
    <>
      <Seo
        title={"Explore Page"}
        description={
          "Explore new things or some adventures things available nearest ot hotel."
        }
      />
      <Layout>
        <div className="header_bottom special-_5">
          <div className="container">
            <BookingOnline />
          </div>
          <BackToTop />
        </div>
        <div className="explore__section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="explore_block">
                  <ExploreText title={title} description={_rawDescription} />
                  <div className="row">
                    {gallery.map((item, index) => (
                      <div className="col-lg-4 col-md-6" key={index}>
                        <ExploreBox
                          press
                          title={item.title}
                          image={item?.thumbnail?.asset.gatsbyImageData}
                          button={{
                            title: "Read",
                          }}
                          index={index}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                            >
                              <path
                                d="M0.5 3.5C0.223858 3.5 0 3.72386 0 4C0 4.27614 0.223858 4.5 0.5 4.5V3.5ZM12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464466C8.97631 0.269204 8.65973 0.269204 8.46447 0.464466C8.2692 0.659728 8.2692 0.976311 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53553C8.65973 7.7308 8.97631 7.7308 9.17157 7.53553L12.3536 4.35355ZM0.5 4.5H12V3.5H0.5V4.5Z"
                                fill="#002643"
                              />
                            </svg>
                          }
                          pageClass={"explore__thumb__bg"}
                          galleryImages={item?.images}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <div className="container">
                      {exploreBig.map((item, index) => (
                        <HistoryBlock
                          key={index}
                          image={
                            item?.thumbnail === null
                              ? false
                              : item?.thumbnail?.asset.gatsbyImageData
                          }
                          description={item?.description}
                          title={item?.title}
                          boxClass={"luxury__enviro__item"}
                          index={index}
                          length={exploreBig.length}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default Explore
export const exploresData = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      exploresPage {
        title
        _rawDescription
        gallery {
          title
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
          images {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
        }
        exploreBig {
          title
          description
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
        }
      }
    }
  }
`
